








































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import Pagination from '@/components/Pagination.vue'
import JsonExcel from 'vue-json-excel'
import { mask } from 'vue-the-mask'
import { OfferStatus } from '@/interfaces/offer'
import ColiderService from '@/services/Colider.service'
import { Prop } from 'vue-property-decorator'

interface FilterOptions {
  status: OfferStatus | null;
  nome: string | null;
}

@Component({
  components: {
    Pagination,
    JsonExcel
  },
  directives: {
    mask
  }
})
export default class InvoicesList extends Vue {
  @Prop({ required: true }) urlColider!: string

  private coliderService = new ColiderService()

  invoices: any[] = []
  $moment

  query = {
    email: '',
    status: ''
  }

  state = {
    loading: false,
    showFilters: false
  }

  async mounted() {
    await this.loadInvoices()
  }

  async loadInvoices() {
    this.state.loading = true
    this.invoices = await this.coliderService.allInvoices(this.urlColider, this.query) as any[]
    this.state.loading = false
  }

  onFilterSubmit() {
    console.log('Teste')
  }

  onFilterReset() {
    this.query = {
      email: '',
      status: ''
    }

    this.loadInvoices()
  }
}
