











































import Vue from 'vue'
import Component from 'vue-class-component'
import Basic from '@/components/offer-setup/Basic.vue'
import OfferService from '@/services/Offer.service'
import ColiderService from '@/services/Colider.service'
import { Offer } from '@/interfaces/offer'
import { NewColiderDTO } from '@/dtos/colider'

@Component({
  components: {
    Basic
  }
})
export default class SetupOfferPage extends Vue {
  offerService: OfferService = new OfferService()
  coliderService: ColiderService = new ColiderService()

  formState = {
    loading: false
  }

  selectedOffer: Offer | null = null

  form: NewColiderDTO = {
    url_oferta: '',
    url_oferta_colider: ''
  }

  offers: Offer[] = []

  async created() {
    this.formState.loading = true
    this.offers = await this.offerService.all()
    this.formState.loading = false
  }

  onOfferSelected(selected) {
    this.form = {
      url_oferta: selected.url_oferta,
      url_oferta_colider: `${selected.url_oferta}-colider`
    }
  }

  get isValid() {
    return !!this.form.url_oferta && !!this.form.url_oferta_colider
  }

  async onFormSubmit() {
    if (!this.isValid) return

    try {
      this.formState.loading = true
      const response = await this.coliderService.new(this.form)
      this.successToast('Oferta Colíder criada com sucesso')
      this.$router.push({ path: `${response.url_oferta_colider}/subconta` })
    } catch (error) {
      if (error instanceof Error) {
        this.errorToast(error?.message)
      }
    } finally {
      this.formState.loading = false
    }
  }

  successToast(msg: string) {
    this.$notify({
      type: 'success',
      title: 'Concluído',
      text: msg,
      group: 'form'
    })
  }

  errorToast(msg: string) {
    this.$notify({
      type: 'error',
      title: 'Ops!',
      text: msg,
      group: 'form'
    })
  }
}

