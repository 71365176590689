





















































































































































































































































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import IuguService from '@/services/Iugu.service'
import { VueMaskDirective } from 'v-mask'
import ColiderService from '@/services/Colider.service'
import { Colider } from '@/interfaces/colider'
import * as lang from 'vuejs-datepicker/src/locale'
import { Money } from 'v-money'
import { InvestorBasicProfile } from '@/interfaces/investor'
import axios, { AxiosError, AxiosResponse } from 'axios'
import InvoicesList from '@/components/lists/InvoicesList.vue'
import * as _ from 'underscore'
import { IuguSubAccount } from '@/interfaces/iugu'
import currency from 'currency.js'

interface Transaction {
  id: string;
  criado: string;
  valor_em_centavos: number;
  valor_localizado: string;
  remetente?: {
    id: string;
    nome: string;
  };
  destinatario?: {
    id: string;
    nome: string;
  };
}

interface TransactionResponse {
  totals: {
    enviados: number;
    recebidos: number;
  };
  transferencias: Transaction[];
}

@Component({
  name: 'index-colider',
  directives: {
    mask: VueMaskDirective
  },
  components: {
    Money,
    InvoicesList
  }
})
export default class IndexColider extends Vue {
  coliderService = new ColiderService()
  iuguService = new IuguService()

  colider!: Colider
  coliderSubAccount: IuguSubAccount | null = null

  languages = lang
  $moment

  profile: InvestorBasicProfile | null = null

  money = {
    decimal: ',',
    thousands: '.',
    prefix: 'R$ ',
    precision: 2,
    masked: false
  }

  cnpjForm = {
    razao_social: '',
    cnpj: ''
  }

  transactions: TransactionResponse = {
    totals: {
      enviados: 0,
      recebidos: 0
    },
    transferencias: []
  } as TransactionResponse

  invoices: any[] = []

  invoice: any = {
    email: '',
    due_date: '',
    items: [{
      description: 'Investimento colíder',
      quantity: 1,
      price_cents: 0
    }],
    payer: {
      cpf_cnpj: '',
      name: '',
      phone_prefix: 0,
      phone: 0,
      email: '',
      address: {
        street: '',
        number: 0,
        city: '',
        state: '',
        country: '',
        district: '',
        zip_code: ''
      }
    }
  }

  coliderSubAccountState = {
    load: {
      loading: false
    }
  }

  transactionsState = {
    transfer: {
      loading: false
    },
    load: {
      loading: false
    }
  }

  invoiceState = {
    loading: false,
    useCNPJ: false,
    errorMessage: ''
  }

  get totalInvestors() {
    const paidInvoices = this.invoices.filter((i: any) => i.status === 'paid')

    return Object.keys(_.groupBy(paidInvoices, 'email')).length
  }

  get isInvoiceValid() {
    return !!this.profile &&
      !!this.invoice.email &&
      !!this.invoice.due_date &&
      !!this.invoice.items[0].price_cents &&
      (this.invoiceState.useCNPJ ? (!!this.cnpjForm.razao_social && !!this.cnpjForm.cnpj) : true)
  }

  get invoicesByStatus() {
    return _.groupBy(this.invoices, 'status')
  }

  async mounted() {
    this.colider = await this.coliderService.findByURL(this.$route.params.url)
    this.loadColiderSubAccount()
    this.loadInvoices()
  }

  async onNewInvoice() {
    try {
      this.invoiceState.loading = true
      const invoiceData = this.buildInvoice()
      await this.coliderService.newInvoice(this.colider.url_oferta_colider, invoiceData)
      this.successToast('Fatura cadastrada com sucesso. Atualize a lista abaixo.')
      this.resetInvoiceForm()
      this.loadInvoices()
    } catch (error) {
      if (error instanceof Error) {
        this.errorToast(error?.message)
      }
    } finally {
      this.invoiceState.loading = false
    }
  }

  buildInvoice() {
    let invoice = Object.assign({}, this.invoice)

    invoice = {
      ...invoice,
      due_date: this.$moment(invoice.due_date).format('DD/MM/YYYY'),
      payer: {
        ...invoice.payer,
        name: this.invoiceState.useCNPJ ? this.cnpjForm.razao_social : this.profile?.nome_completo,
        cpf_cnpj: this.invoiceState.useCNPJ ? this.cnpjForm.cnpj : this.profile?.cpf,
        email: this.profile?.email,
        phone_prefix: this.profile?.endereco.telefone.slice(0, 2),
        phone: this.profile?.endereco.telefone.slice(2,),
        address: {
          street: this.profile?.endereco.rua,
          number: this.profile?.endereco.numero,
          city: this.profile?.endereco.cidade,
          state: this.profile?.endereco.estado,
          country: 'Brasil',
          district: this.profile?.endereco.bairro,
          zip_code: this.profile?.endereco.cep
        }
      }
    }

    invoice.items[0].price_cents = invoice.items[0].price_cents * 100

    return invoice
  }

  async onEmailChange(event: InputEvent) {
    this.invoiceState.loading = true
    const email = (event.target as HTMLInputElement).value

    try {
      this.profile = await this.findUserByEmail(email)
      this.invoiceState.errorMessage = ''
    } catch (error) {
      const message = (error as any).message || 'Erro inesperado.'
      this.invoiceState.errorMessage = message
      this.errorToast(message)
    } finally {
      this.invoiceState.loading = false
    }
  }

  findUserByEmail(email: string) {
    const endpoint = `${process.env.VUE_APP_CADASTRO_ENDPOINT}/Cadastro/v1/basico`
    return axios
      .get(endpoint, { params: { termoBusca: email } })
      .then((response: AxiosResponse) => {
        if (!response.data) {
          return Promise.reject(new Error('Investidor não encontrado.'))
        }

        if (response.data && !response.data.habilitado) {
          return Promise.reject(new Error('Investidor não está habilitado. É preciso que ele complete seu cadastro em https://smu.com.vc/investidor/dashboard/perfil'))
        }

        return response.data
      })
      .catch((err: Error | AxiosError) => {
        if (axios.isAxiosError(err)) {
          return Promise.reject(new Error(err.request?.response))
        } else {
          return Promise.reject(new Error(err.message))
        }
      })
  }

  resetInvoiceForm() {
    this.invoice = {
      email: '',
      due_date: '',
      items: [{
        description: 'Investimento colíder',
        quantity: 1,
        price_cents: 0
      }],
      payer: {
        cpf_cnpj: '',
        name: '',
        phone_prefix: 0,
        phone: 0,
        email: '',
        address: {
          street: '',
          number: 0,
          city: '',
          state: '',
          country: '',
          district: '',
          zip_code: ''
        }
      }
    }

    this.cnpjForm = {
      razao_social: '',
      cnpj: ''
    }

    this.invoiceState.useCNPJ = false
  }

  async loadColiderSubAccount() {
    this.coliderSubAccountState.load.loading = true
    this.coliderSubAccount = await this.coliderService.subAccount(this.$route.params.url)
    this.coliderSubAccountState.load.loading = false
  }

  async loadInvoices() {
    this.invoiceState.loading = true
    this.invoices = await this.coliderService.allInvoices(this.$route.params.url, {} as any)
    this.invoiceState.loading = false
  }

  async loadTransactions() {
    this.transactionsState.load.loading = true
    this.transactions = await this.coliderService.allTransactions(this.colider.url_oferta_colider)
    this.transactionsState.load.loading = false
  }

  async transferToOfferAccount() {
    const response = window.confirm('Todo o saldo será transferido. Tem certeza que deseja realizar essa operação?')
    if (!response) return

    this.transactionsState.transfer.loading = true
    const balanco = currency(this.coliderSubAccount?.balanco || '', { separator: '.', decimal: ',' }).value

    const transferResponse = await this.coliderService.transfer({
      remetente: this.colider.url_oferta_colider,
      destinatario: this.colider.oferta.url_oferta,
      valor_em_centavos: balanco * 100
    })

    if (transferResponse.errors) {
      this.errorToast(transferResponse.errors)
    } else {
      this.successToast('Pedido de transferência solicitado com sucesso. Acompanhe o histórico de transferências.')
    }

    this.transactionsState.transfer.loading = false
  }

  successToast(msg: string) {
    this.$notify({
      type: 'success',
      title: 'Concluído',
      text: msg,
      group: 'form'
    })
  }

  errorToast(msg: string) {
    this.$notify({
      type: 'error',
      title: 'Ops!',
      text: msg,
      group: 'form'
    })
  }
}
