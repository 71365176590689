










































































import Vue from 'vue'
import Component from 'vue-class-component'
import Pagination from '@/components/Pagination.vue'
import JsonExcel from 'vue-json-excel'
import { mask } from 'vue-the-mask'
import ColiderService from '@/services/Colider.service'
import { Colider } from '@/interfaces/colider'

@Component({
  components: {
    Pagination,
    JsonExcel
  },
  directives: {
    mask
  }
})
export default class Users extends Vue {
  private coliderService = new ColiderService()

  colideres: Colider[] = []

  state = {
    loading: false
  }

  async mounted() {
    await this.loadColideres()
  }

  async loadColideres() {
    this.state.loading = true
    this.colideres = await this.coliderService.all() as any[]
    this.state.loading = false
  }
}
