








































































import Vue from 'vue'
import Component from 'vue-class-component'
import IuguService from '@/services/Iugu.service'
import { Offer } from '@/interfaces/offer'
import { IuguSubAccount, BasicSubAccountForm, FullSubAccountForm, IuguBank } from '@/interfaces/iugu'
import ColiderService from '@/services/Colider.service'
import { Colider } from '@/interfaces/colider'
import OfferService from '@/services/Offer.service'

@Component({
  name: 'offer-colider-subaccount-form'
})
export default class Subconta extends Vue {
    iuguService = new IuguService()
    coliderService = new ColiderService()
    offerService = new OfferService()

    colider!: Colider
    coliderSubAccount: IuguSubAccount = {} as IuguSubAccount

    offer!: Offer

    iuguBanks: IuguBank[] = []
    iuguBankOptions: string[] = []

    basicForm: BasicSubAccountForm = {
      name: '',
      url_oferta: '',
      comission_percent: ''
    }

    fullForm: FullSubAccountForm = {
      business_type: 'Crowdfunding de Investimentos',
      cnpj: '17605805000161',
      company_name: '',
      address: 'Avenida Angélica, 2529',
      cep: '01227-200',
      bank: 'Itaú',
      bank_ag: '1664',
      bank_cc: '27556-5',
      city: 'São Paulo',
      state: 'SP',
      account_type: 'Corrente'
    } as FullSubAccountForm

    formState: { [key: string]: any } = {
      loading: false
    }

    get isBasicFormValid() {
      return !!this.basicForm.name && !!this.basicForm.comission_percent && !!this.basicForm.url_oferta
    }

    async mounted() {
      this.colider = await this.coliderService.findByURL(this.$route.params.url)
      this.coliderSubAccount = await this.iuguService.findSubAccount(this.colider.url_oferta_colider)

      this.basicForm = {
        name: `Colider - ${this.colider.oferta.nome_oferta}`,
        url_oferta: this.colider.url_oferta_colider
      } as BasicSubAccountForm

      this.iuguBanks = await this.iuguService.banks()
      this.iuguBankOptions = this.iuguBanks.map(b => b.bank_name)
    }

    async onBasicFormSubmit() {
      this.formState.loading = true

      try {
        const form = { ...this.basicForm }
        form.comission_percent = parseFloat(form.comission_percent).toFixed(2)

        await this.iuguService.newBasicSubAccount(form)

        this.fullForm.company_name = `Colider - SMU/${this.colider.oferta.nome_oferta}`

        await this.iuguService.newFullSubAccount(this.colider.url_oferta_colider, this.fullForm)
        await this.iuguService.updatePaymentMethods(this.colider.url_oferta_colider, { metodos_pagamento: ['PIX', 'BOLETO', 'CARTAO'] })

        this.successToast('Subconta para a Oferta Colíder foi criada com sucesso.')
        this.$router.push({ path: `/ofertas-colider/${this.colider.url_oferta_colider}` })
      } catch (error) {
        if (error instanceof Error) {
          this.errorToast(error?.message)
        }
      } finally {
        this.formState.loading = false
      }
    }

    successToast(msg: string) {
      this.$notify({
        type: 'success',
        title: 'Concluído',
        text: msg,
        group: 'form'
      })
    }

    errorToast(msg: string) {
      this.$notify({
        type: 'error',
        title: 'Ops!',
        text: msg,
        group: 'form'
      })
    }
}
