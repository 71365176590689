import { NewColiderDTO } from '@/dtos/colider'
import { Colider } from '@/interfaces/colider'
import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios'

export default class ColiderService {
  private OFFER_ENDPOINT = process.env.VUE_APP_OFFER_ENDPOINT
  private IUGU_ENDPOINT = process.env.VUE_APP_IUGU_ENDPOINT

  private client: AxiosInstance

  constructor() {
    this.client = axios
  }

  all(): Promise<Colider[]> {
    return this.client
      .get(`${this.OFFER_ENDPOINT}/oferta/lista/colider`)
      .then((response: AxiosResponse) => response.data)
  }

  subAccount(urlColider: string) {
    return this.client
      .get(`${this.IUGU_ENDPOINT}/iugu/subconta/busca/resumo/${urlColider}`)
      .then(response => response.data)
      .catch((err: Error | AxiosError) => {
        if (axios.isAxiosError(err)) {
          return Promise.reject(new Error(err.request?.response))
        } else {
          return Promise.reject(new Error(err.message))
        }
      })
  }

  allInvoices(urlColider: string, query: { email: string; status: string }) {
    return this.client
      .get(`${this.IUGU_ENDPOINT}/iugu/fatura/lista/${urlColider}`, { params: query })
      .then((response: AxiosResponse) => {
        return response.data
      })
      .catch((err: Error | AxiosError) => {
        if (axios.isAxiosError(err)) {
          return Promise.reject(new Error(err.request?.response))
        } else {
          return Promise.reject(new Error(err.message))
        }
      })
  }

  allTransactions(urlColider: string, pagination = { init: 0, limit: 50 }) {
    return this.client
      .get(`${this.IUGU_ENDPOINT}/iugu/subconta/transferencias/${urlColider}`, { params: pagination })
      .then((response: AxiosResponse) => {
        return response.data
      })
      .catch((err: Error | AxiosError) => {
        if (axios.isAxiosError(err)) {
          return Promise.reject(new Error(err.request?.response))
        } else {
          return Promise.reject(new Error(err.message))
        }
      })
  }

  findByURL(url): Promise<Colider> {
    return this.client
      .get(`${this.OFFER_ENDPOINT}/oferta/busca/colider/${url}`)
      .then((response: AxiosResponse) => response.data)
  }

  new(data: NewColiderDTO): Promise<Colider> {
    return this.client
      .post(`${this.OFFER_ENDPOINT}/oferta/nova/colider`, { ...data })
      .then((response: AxiosResponse) => response.data)
      .catch((err: Error | AxiosError) => {
        if (axios.isAxiosError(err)) {
          return Promise.reject(new Error(err.request?.response))
        } else {
          return Promise.reject(new Error(err.message))
        }
      })
  }

  newInvoice(urlColider: string, invoiceData: any): Promise<any> {
    return this.client
      .post(`${this.IUGU_ENDPOINT}/iugu/fatura/colider/${urlColider}`, { ...invoiceData })
      .then((response: AxiosResponse) => {
        return response.data
      })
      .catch((err: Error | AxiosError) => {
        if (axios.isAxiosError(err)) {
          return Promise.reject(new Error(err.request?.response))
        } else {
          return Promise.reject(new Error(err.message))
        }
      })
  }

  transfer(data: { remetente: string; destinatario: string; valor_em_centavos: number }) {
    return this.client
      .post(`${this.IUGU_ENDPOINT}/iugu/subconta/transferencias/${data.remetente}`, { destinatario: data.destinatario, valor_em_centavos: data.valor_em_centavos })
      .then((response: AxiosResponse) => {
        return response.data
      })
      .catch((err: Error | AxiosError) => {
        if (axios.isAxiosError(err) && err.response?.status === 400) {
          return { errors: err.response?.data.message }
        }

        if (axios.isAxiosError(err) && err.response?.status === 404) {
          return { errors: err.response?.data }
        }
      })
  }
}

interface IuguValidationError {
  [x: string]: string[];
}
